import { useState, useEffect } from "react";
import "./cameraDetail.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowUp,
	faArrowDown,
	faArrowRight,
	faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

const SelectDirection = (props) => {
	const { setDirectionTypeIn, setDirectionTypeOut } = props;
	return (
		<>
			<div className='group-button d-flex flex-column'>
				<div>
					<div className='button-title'>入口方向</div>
					<div className='group-button1 flex-column d-flex al-center j-center'>
						<div
							className='w-full d-flex flex-row j-sp-bw'
							style={{ marginBottom: "10px" }}
						>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeIn(1)}
							>
								<FontAwesomeIcon icon={faArrowRight} size='lg' />
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeIn(2)}
							>
								<FontAwesomeIcon icon={faArrowLeft} size='lg' />
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeIn(3)}
							>
								<FontAwesomeIcon icon={faArrowUp} size='lg' />
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeIn(4)}
							>
								<FontAwesomeIcon icon={faArrowDown} size='lg' />
							</div>
						</div>
						<div className='w-full d-flex flex-row f-be j-sp-bw'>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeIn(5)}
							>
								<FontAwesomeIcon
									icon={faArrowUp}
									size='lg'
									className='ar-up-right'
								/>
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeIn(6)}
							>
								<FontAwesomeIcon
									icon={faArrowDown}
									size='lg'
									className='ar-up-right'
								/>
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeIn(7)}
							>
								<FontAwesomeIcon
									icon={faArrowLeft}
									size='lg'
									className='ar-up-right'
								/>
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeIn(8)}
							>
								<FontAwesomeIcon
									icon={faArrowRight}
									size='lg'
									className='ar-up-right'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='flex-column d-flex j-center'>
					<div className='button-title'>出口方向</div>
					<div className='group-button1 flex-column d-flex al-center j-center'>
						<div
							className='w-full d-flex flex-row j-sp-bw'
							style={{ marginBottom: "10px" }}
						>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeOut(1)}
							>
								<FontAwesomeIcon icon={faArrowRight} size='lg' />
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeOut(2)}
							>
								<FontAwesomeIcon icon={faArrowLeft} size='lg' />
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeOut(3)}
							>
								<FontAwesomeIcon icon={faArrowUp} size='lg' />
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeOut(4)}
							>
								<FontAwesomeIcon icon={faArrowDown} size='lg' />
							</div>
						</div>
						<div className='w-full d-flex flex-row f-be j-sp-bw'>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeOut(5)}
							>
								<FontAwesomeIcon
									icon={faArrowUp}
									size='lg'
									className='ar-up-right'
								/>
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeOut(6)}
							>
								<FontAwesomeIcon
									icon={faArrowDown}
									size='lg'
									className='ar-up-right'
								/>
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeOut(7)}
							>
								<FontAwesomeIcon
									icon={faArrowLeft}
									size='lg'
									className='ar-up-right'
								/>
							</div>
							<div
								className='button-arrow d-flex al-center j-center'
								onClick={() => setDirectionTypeOut(8)}
							>
								<FontAwesomeIcon
									icon={faArrowRight}
									size='lg'
									className='ar-up-right'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default SelectDirection;
