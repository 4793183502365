import "./App.css";
import SideBar from "./components/SideBar";
import Header from "./components/Header";

import { useSelector } from "react-redux";

import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard";
import Parkings from "./pages/parkings";
import Cameras from "./pages/cameras";
import CameraDetail from "./pages/cameraDetail";
import "font-awesome/css/font-awesome.min.css";
import { useEffect, useState } from "react";
import "react-dropdown/style.css";
import WebFont from "webfontloader";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RootState } from "./store/index";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import ParkingMap from "./pages/parkingCal";
import { Helmet } from "react-helmet";

const env = process.env.REACT_APP_ENV;
console.log(env);
const PrivateRoute = ({ isLoggedIn, children }) => {
	if (!isLoggedIn) {
		window.location.href = "/login";
	}

	return children;
};

function App() {
	const { isLoggedIn } = useSelector((state: RootState) => state.auth);
	const [isChangeTab, setChangeTab] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const path = window.location.pathname;

	useEffect(() => {
		WebFont.load({
			google: {
				families: ["Inter"],
			},
		});
	}, []);
	return (
		<Router>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoading}
				// onClick={handleClose}
			>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Helmet>
				<meta
					name='robots'
					content={env && env === "product" ? "index,follow" : "noindex"}
				/>
			</Helmet>
			<div className='main d-flex flex-column'>
				<Header></Header>
				<div
					className={
						path === "/parking-cal"
							? "content-body-w d-flex flex-row"
							: "content-body d-flex flex-row"
					}
				>
					<SideBar setChangeTab={setChangeTab} isChangeTab={isChangeTab} />
					<Routes>
						<Route
							path='/'
							element={
								<PrivateRoute isLoggedIn={isLoggedIn}>
									<Dashboard setLoading={setLoading} />
								</PrivateRoute>
							}
						/>
						<Route
							path='/parkings'
							element={
								<PrivateRoute isLoggedIn={isLoggedIn}>
									<Parkings setLoading={setLoading} />
								</PrivateRoute>
							}
						/>
						<Route
							path='/cameras'
							element={
								<PrivateRoute isLoggedIn={isLoggedIn}>
									<Cameras setLoading={setLoading} />
								</PrivateRoute>
							}
						/>
						<Route
							path='/cameras/:id'
							element={
								<PrivateRoute isLoggedIn={isLoggedIn}>
									<CameraDetail setLoading={setLoading} isLoading={isLoading} />
								</PrivateRoute>
							}
						/>

						<Route
							path='/parking-cal'
							element={
								<ParkingMap setLoading={setLoading} isLoading={isLoading} />
							}
						/>

						<Route path='/login' element={<Login />} />
					</Routes>
				</div>
			</div>
		</Router>
	);
}

export default App;
