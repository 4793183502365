import React, { useState, useEffect } from "react";
import "./cameras.css";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useModal } from "react-hooks-use-modal";
// import camerasParkingsService from "../../services/cameraParkingService";
import camerasService from "../../services/cameraService";
import Items from "./Items";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#3A4C7A",
		color: "#FFFFFF",
		fontSize: "18px",
		fontStyle: "normal",
		lineHeight: "22px",
		fontWeight: 400,
		fontFamily: "Inter",
	},
	[`&.${tableCellClasses.body}`]: {
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: "18px",
		lineHeight: "22px",
		color: "#040C24",
	},
}));

const Cameras = (props) => {
	const { setLoading } = props;
	const [nameSearch, setNameSearch] = useState("");
	const [searchResult, setSearchResult] = useState<any>(null);
	const [camerasRq, setCamerasRq] = useState<any>(false);
	const [listCamera, setListCamera] = useState<any>(null);
	const [openOffCam, setOpenOffCam] = useState<any>(null);
	const [openResetCam, setOpenResetCam] = useState<any>(null);
	const [Modal, open, close, isOpen] = useModal("root");

	const [ModalResetCam, openReset, closeResetCam, isOpenResetCam] =
		useModal("root");

	const [isUpdated, setUpdate] = useState<any>(false);

	const getListCameras = async () => {
		// setLoading(true);
		try {
			const response = await camerasService.getCamera();
			if (response !== null && response !== undefined) {
				setListCamera(response.items);
				let checkRequest = false;
				response.items.map((item) => {
					if (item.request) {
						checkRequest = true;
					}
				});
				setCamerasRq(checkRequest);
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			// setLoading(false);
		}
	};

	const search = (key, inputArray) => {
		let arr: Array<any> = [];
		if (key !== "") {
			for (let i = 0; i < inputArray.length; i++) {
				if (
					inputArray[i]?.name.toLowerCase().includes(key.toLowerCase()) ||
					inputArray[i]?.parking_name
						?.toLowerCase()
						?.includes(key.toLowerCase())
				) {
					arr.push(inputArray[i]);
				}
			}
			return arr;
		} else return inputArray;
	};

	const handleSearchName = () => {
		let result: any = null;
		result = search(nameSearch, listCamera);
		setSearchResult(result);
	};

	const resetCam = async (id: any) => {
		try {
			const response = await camerasService?.resetInOut(id, {
				num_car_in: 0,
				num_car_out: 0,
			});
			if (response !== null && response !== undefined) {
				setUpdate(!isUpdated);
			}
		} catch (err) {
			console.log("err", err);
		}
	};

	useEffect(() => {
		getListCameras();
	}, [isUpdated]);

	useEffect(() => {
		handleSearchName();
	}, [listCamera, nameSearch]);

	useEffect(() => {
		const interval = setInterval(() => {
			getListCameras();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (openOffCam !== null) {
			open();
		}
		if (openResetCam !== null) {
			openReset();
		}
	}, [openOffCam, openResetCam]);

	useEffect(() => {
		if (!isOpen) {
			setOpenOffCam(null);
		}
		if (!isOpenResetCam) {
			setOpenResetCam(null);
		}
	}, [isOpen, isOpenResetCam]);

	return (
		<div className='parkings'>
			<div className='parkings-container d-flex flex-column'>
				<div className='parkings-title'>カメラ</div>
				<div className='cameras-search d-flex flex-row'>
					<div className='search-field d-flex flex-column'>
						<div className='search-dropdown-title'>キーワード</div>
						<div className='search-form d-flex flex-row'>
							<input
								autoComplete='off'
								type='text'
								required
								value={nameSearch}
								onChange={(e) => setNameSearch(e.target.value)}
							></input>
							<div
								className='search-button d-flex flex-row j-center al-center'
								onClick={handleSearchName}
							>
								検索
							</div>
						</div>
					</div>
				</div>
				<div className='cameras-table'>
					<TableContainer component={Paper}>
						<Table aria-label='customized table'>
							<TableHead>
								<TableRow>
									<StyledTableCell align='left'>カメラ</StyledTableCell>
									<StyledTableCell align='left'>駐車場・入り口</StyledTableCell>
									<StyledTableCell align='left'>状態</StyledTableCell>
									<StyledTableCell align='left'>画像送信の状態</StyledTableCell>
									<StyledTableCell align='left'>信号ステータス</StyledTableCell>
									<StyledTableCell align='center'>操作</StyledTableCell>
								</TableRow>
							</TableHead>
							<Items
								listCamera={searchResult}
								camerasRq={camerasRq}
								getListCameras={getListCameras}
								setOpenOffCam={setOpenOffCam}
								setOpenResetCam={setOpenResetCam}
								setUpdate={setUpdate}
								isUpdated={isUpdated}
							/>
						</Table>
					</TableContainer>
					<div className='reset-request'>
						<button
							className={camerasRq ? "button-tb3" : "button-tb2 bg-disable"}
							disabled={!camerasRq}
							onClick={() => {
								camerasService.cancelRequestCameraImage();
								setTimeout(() => {
									getListCameras();
								}, 1000);
							}}
							style={{ width: "170px" }}
						>
							リクエストを削除
						</button>
					</div>
				</div>
			</div>
			<Modal>
				<div className='off-cam-modal d-flex flex-column al-center'>
					<label className='title-off-cam'>電源をOFFにしますか？</label>
					<div className='off-cam-buttons-gr d-flex flex-row w-full'>
						<button
							className='off-cam-buttons d-flex flex-row j-center al-center'
							onClick={async () => {
								setOpenOffCam(null);
								const data = await camerasService.turnOffCamera(
									openOffCam?.id,
									true,
								);

								const data2 = await camerasService.resetStatus(openOffCam?.id);
								if (data && data2) getListCameras();
								close();
							}}
						>
							はい
						</button>
						<button
							className='off-cam-buttons d-flex flex-row j-center al-center bg-delete'
							onClick={() => {
								setOpenOffCam(null);
								close();
							}}
						>
							いいえ
						</button>
					</div>
				</div>
			</Modal>

			<ModalResetCam>
				<div className='off-cam-modal d-flex flex-column al-center'>
					<label className='title-off-cam'>リセットしましすか？</label>
					<div className='off-cam-buttons-gr d-flex flex-row w-full'>
						<button
							className='off-cam-buttons d-flex flex-row j-center al-center'
							onClick={async () => {
								setOpenResetCam(null);
								resetCam(openResetCam?.id);
								closeResetCam();
							}}
						>
							はい
						</button>
						<button
							className='off-cam-buttons d-flex flex-row j-center al-center bg-delete'
							onClick={() => {
								setOpenResetCam(null);
								closeResetCam();
							}}
						>
							いいえ
						</button>
					</div>
				</div>
			</ModalResetCam>
		</div>
	);
};
export default Cameras;
