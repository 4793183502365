import Api from './api/cameraApi';

const getCameraImage = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(`/${id}/image`, {
                    headers: { Authorization: `Bearer ${user.access_token}`, },
                })
                .then((response) => {
                    // console.log("response", response)
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log("err", err);
            // window.location.href = '/login';
            return null;
        }
    } else {
        console.log("err");
        // window.location.href = '/login';
    }
};

const getCameraIp = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(`/${id}/ip`, {
                    headers: { Authorization: `Bearer ${user.access_token}`, },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log("err", err);
            return null;
        }
    } else {
        console.log("err");
    }
};

const getCamera = async () => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(``, {
                    headers: { Authorization: `Bearer ${user.access_token}`, },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log("err", err);
            window.location.href = '/login';
            return null;
        }
    } else {
        console.log("err");
        window.location.href = '/login';
    }
};

const getCameraDetail = async (id: number) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(`${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}`, },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log("err", err);
            window.location.href = '/login';
            return null;
        }
    } else {
        console.log("err");
        window.location.href = '/login';
    }
};

const requestCameraImage = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    try {
        return await Api()
            .post(`${id}/request`, {}, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    }
    catch (err) {
        console.log("err", err);
        return null;
    }
};

const cancelRequestCameraImage = async () => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    return await Api()
        .post(`reset-all-camera-id?reset_camera_id=1`, {}, {
            headers: { Authorization: `Bearer ${user.access_token}` },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const requestCameraConfig = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    return await Api()
        .post(`${id}/request-config`, {}, {
            headers: { Authorization: `Bearer ${user.access_token}` },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const turnOffCamera = async (id: number, status_camera: boolean) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    return await Api()
        .post(`${id}/turn-on-off/${status_camera}`, {}, {
            headers: { Authorization: `Bearer ${user.access_token}` },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};


const resetCamStatus = async (id: number) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    try {
        return await Api()
            .post(`/${id}/rssi-sni/${0}/${0}`, {}, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    console.log(response.data);
                    return response.data;
                }
            });
    } catch (err) {
        window.location.href = '/login';
        return null;
    }
};

const updateCamSimLora = async (id: any, lora: boolean, sim: boolean) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    try {
        return await Api()
            .post(`${id}/lora-sim/${lora}/${sim}`, {}, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (err) {
        // window.location.href = '/login';
        return null;
    }
};

const updateCameraConfig = async (id: number, input: any) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    return await Api()
        .put(`${id}`, input, {
            headers: { Authorization: `Bearer ${user.access_token}` },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const resetInOut = async (id: number, input: any) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    return await Api()
        .put(`/counter/${id}`, input, {
            headers: { Authorization: `Bearer ${user.access_token}` },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const resetStatus = async (camera_id: number) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;

    return await Api()
        .put(`${camera_id}`, { status: 0 }, {
            headers: { Authorization: `Bearer ${user.access_token}` },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const cameraService = {
    getCameraImage,
    getCameraIp,
    requestCameraImage,
    getCamera,
    requestCameraConfig,
    cancelRequestCameraImage,
    turnOffCamera,
    resetCamStatus,
    updateCamSimLora,
    getCameraDetail,
    updateCameraConfig,
    resetInOut,
    resetStatus
};

export default cameraService;