import { useState, useEffect } from "react";
import "./sidebar.css";
import iconParking from "../../assets/imgs/car-parking.svg";
import iconTop from "../../assets/imgs/top.svg";
import logout1 from "../../assets/imgs/logout.svg";
import monitor from "../../assets/imgs/monitor.svg";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/auth/auth";
import { useAppDispatch } from "../../store/index";
import Modal from "../Modal/Modal";
import useModal from "../../hooks/useModal";

declare let window: any;

const SideBar = (props) => {
	const { setChangeTab, isChangeTab } = props;
	const navigate = useNavigate();
	const pathTemp = window.location.pathname;

	const [hide, setHide] = useState(false);
	const dispatch = useAppDispatch();
	const [path, setPath] = useState("");

	const { isShowing, toggle } = useModal();

	useEffect(() => {
		let pathTemp = window.location.pathname;
		setPath(pathTemp);
		if (pathTemp === "/login" || path === "/parking-cal") {
			setHide(true);
		}
	});

	return (
		<div
			className='sidebar'
			style={{
				display: pathTemp == "/login" || path === "/parking-cal" ? "none" : "",
			}}
		>
			<div className='menu-list'>
				<p
					className='menu-item d-flex flex-row'
					onClick={() => {
						navigate("/");
						setChangeTab(!isChangeTab);
					}}
					style={{ background: path === "/" ? "#3A4C7A" : "" }}
				>
					<img width={"24px"} height={"24px"} src={iconTop}></img>
					トップ
				</p>
				<p
					className='menu-item d-flex flex-row'
					onClick={() => {
						navigate("/parkings");
						setChangeTab(!isChangeTab);
					}}
					style={{ background: path === "/parkings" ? "#3A4C7A" : "" }}
				>
					<img width={"24px"} height={"24px"} src={iconParking}></img>
					駐車場
				</p>
				<p
					className='menu-item d-flex flex-row'
					onClick={() => {
						navigate("/cameras");
						setChangeTab(!isChangeTab);
					}}
					style={{
						background:
							path === "/cameras" || path.startsWith("/cameras")
								? "#3A4C7A"
								: "",
					}}
				>
					<img width={"24px"} height={"24px"} src={monitor}></img>
					カメラ
				</p>
				{/* <p
          className="menu-item d-flex flex-row"
          // onClick={() => { navigate('/setting'); setChangeTab(!isChangeTab) }}
          style={{ background: path === "/setting" ? "#3A4C7A" : "" }}
        >
          <img width={"24px"} height={"24px"} src={setting}></img>
          設定
        </p> */}
				<p
					className='menu-item d-flex flex-row'
					onClick={() => {
						toggle();
					}}
					style={{ background: path === "/logout" ? "#3A4C7A" : "" }}
				>
					<img width={"24px"} height={"24px"} src={logout1}></img>
					ログアウト
				</p>
			</div>
			<Modal
				isShowing={isShowing}
				hide={toggle}
				ModalBody={() => {
					return (
						<div className='logout-alerts d-flex flex-column'>
							<div className='text-logout-title'>
								本当にログアウトしますか？
							</div>

							<div className='logout-gr'>
								<button
									className='logout-button bt-cancel'
									onClick={() => toggle()}
								>
									キャンセル
								</button>

								<button
									className='logout-button bt-ok'
									onClick={() => {
										dispatch(logout());
										navigate("/login");
										toggle();
									}}
								>
									ログアウト
								</button>
							</div>
						</div>
					);
				}}
			/>
		</div>
	);
};
export default SideBar;
