import Api from './api/parkingApi';

const getListParkings = async (input: any = {}) => {
    try {
        return await Api()
            .get('')
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (err) {
        window.location.href = '/login';
        return null;
    }
};

const updateParking = async (id, input) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    try {
        return await Api()
            .put(`${id}`, input, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (err) {
        window.location.href = '/login';
        return null;
    }
};


const parkingsService = {
    getListParkings,
    updateParking,
};

export default parkingsService;