import axios from 'axios';
import Api from './api/authApi';

const login = async (body: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('username', body.username);
    bodyFormData.append('password', body.password);

    var response = await Api().post('login', bodyFormData);
    localStorage.setItem("user", JSON.stringify(response.data));
    return response.data;
};
const register = async (body: any) => {
    var response = await Api().post('register', body);
    return response.data;
};
const refreshToken = async (body: any) => {
    var response = await Api().post('refresh', body);
    return response.data;
};

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("access_token");
};

const getLogErr = async () => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const api = process.env.REACT_APP_SERVER_API || 'https://api-cp-counter.javis.vn';

    return await axios
        .get(`${api}/logs`, {
            headers: { Authorization: `Bearer ${user.access_token}` },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        }).catch((err) => {
            return (err);
        });
};
const authService = {
    login,
    register,
    refreshToken,
    logout,
    getLogErr
};

export default authService;
