import React, { useState, useEffect } from "react";
import "./dashboard.css";
import ProgressBar from "@ramonak/react-progress-bar";
import parkingsService from "../../services/parkingsService";

import { ToastContainer, toast } from "react-toastify";

const Dashboard = (props: any) => {
	const { setLoading } = props;
	const [parkingsList, setParkingsList] = useState<any>(null);
	console.log(window.location.origin);

	const notify = () =>
		toast.success("コピーが完了しました。", {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
			theme: "light",
		});

	const getListParkings = async (loading = false) => {
		try {
			loading && setLoading(true);
			const response = await parkingsService.getListParkings({});
			if (response !== null && response !== undefined) {
				setParkingsList(response.items);
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			loading && setLoading(false);
		}
	};

	useEffect(() => {
		getListParkings(true);
		const interval = setInterval(() => {
			getListParkings();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className='dashboard'>
			<div className='dashboard-container d-flex flex-column'>
				<div className='table-progress'>
					<div className='table-progress-row d-flex'>
						{parkingsList !== null &&
							parkingsList.length > 0 &&
							parkingsList.map(
								(item, index) =>
									index < 17 && (
										<div
											key={index}
											className='table-progress-item d-flex flex-row'
										>
											<div className='table-progress-title d-flex flex-row j-center al-center'>
												<label className='truncate-text'>{item.name}</label>
											</div>
											<div className='table-progress-content d-flex flex-column j-center al-center'>
												<div className='progress-text d-flex flex-column w-full j-center al-center'>
													{item.now_num_cars}台｜{item.max_num_cars}台
												</div>
												<div className='progress-info d-flex flex-column w-full j-center al-center'>
													<ProgressBar
														completed={Math.floor(
															item.now_num_cars >= 0
																? (item.now_num_cars * 100) / item.max_num_cars
																: 0,
														)}
														className='wrapper'
														baseBgColor={"#A5AAB4"}
														bgColor={"#0A205A"}
														labelColor={"#0A205A"}
													/>
												</div>
											</div>
										</div>
									),
							)}
					</div>
				</div>
				<div className='dashboard-button d-flex flex-row j-center al-center w-full'>
					<div
						className='d-flex flex-row j-center al-center w-full'
						onClick={() => {
							navigator.clipboard.writeText(
								`<iframe src=${window.location.origin}/parking-cal" width="1080" height="550" title="parking-cal"></iframe>`,
							);
							notify();
						}}
					>
						WEBツールタグコピー
					</div>
				</div>
				<ToastContainer
					position='top-right'
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme='colored'
				/>
			</div>
		</div>
	);
};
export default Dashboard;
