import { useState, useEffect } from "react";
import "./cameraDetail.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  //   faArrowUpRight,
  //   faArrowDownRight,
  faArrowUpRightDots,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
const Direction = (props) => {
  const { id } = props;
  return (
    <>
      {id === 1 && (
        <div className="button-arrow d-flex al-center j-center">
          <FontAwesomeIcon icon={faArrowRight} size="lg" />
        </div>
      )}
      {id === 2 && (
        <div className="button-arrow d-flex al-center j-center">
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </div>
      )}
      {id === 3 && (
        <div className="button-arrow d-flex al-center j-center">
          <FontAwesomeIcon icon={faArrowUp} size="lg" />
        </div>
      )}
      {id === 4 && (
        <div className="button-arrow d-flex al-center j-center">
          <FontAwesomeIcon icon={faArrowDown} size="lg" />
        </div>
      )}
      {id === 5 && (
        <div className="button-arrow d-flex al-center j-center">
          <FontAwesomeIcon icon={faArrowUp} size="lg" className="ar-up-right" />
        </div>
      )}
      {id === 6 && (
        <div className="button-arrow d-flex al-center j-center">
          <FontAwesomeIcon
            icon={faArrowDown}
            size="lg"
            className="ar-up-right"
          />
        </div>
      )}
      {id === 7 && (
        <div className="button-arrow d-flex al-center j-center">
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="lg"
            className="ar-up-right"
          />
        </div>
      )}
      {id === 8 && (
        <div className="button-arrow d-flex al-center j-center">
          <FontAwesomeIcon
            icon={faArrowRight}
            size="lg"
            className="ar-up-right"
          />
        </div>
      )}
    </>
  );
};
export default Direction;
