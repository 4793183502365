export const status = ["停止", "稼働中", "エラー"];
export const signalStatus = [
	"ロラは弱い", // lora yếu
	"ロラは通常", // lora trung bình
	"ロラは強い", // lora mạnh
	"シム接続", // sim connected
	"SIM切断", // sim disconnected
];

export const configDirection = {
	right: 1,
	left: 2,
	top: 3,
	down: 4,
	topRight: 5,
	downLeft: 6,
	topLeft: 7,
	downRight: 8,
};

export const parkingLocation = [
	"https://maps.app.goo.gl/YrUkJEVJx6cDCk3s6",
	"https://maps.app.goo.gl/6vuYBTr1Riq26KbZ9",
	"https://maps.app.goo.gl/XoGFTufvESbSHBSg9",
	"https://maps.app.goo.gl/3vdbuuHePMwhYM5UA",
	"https://maps.app.goo.gl/oZkMLR1LPXMhKytQ7",
	"https://maps.app.goo.gl/Je6s4rLWnDqqLW987",
	"https://maps.app.goo.gl/zeSfy3PgaYSSznk6A",
];

export const parkingLocationDemo = [
	"https://maps.app.goo.gl/jvoS2pAQPLDw4YUAA",
	"https://maps.app.goo.gl/F8CnUHUuakSW6g1A6",
	"https://maps.app.goo.gl/1KNaZ7feuLvMDYjR6?g_st=ic",
];
