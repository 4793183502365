import React from "react";
import ReactDOM from "react-dom";
import "./ModalCustom.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ isShowing, hide, ModalBody }) =>
	isShowing
		? ReactDOM.createPortal(
				<React.Fragment>
					<div className='modal-overlay' />
					<div
						className='modal-wrapper'
						aria-modal
						aria-hidden
						tabIndex={-1}
						role='dialog'
					>
						<div className='body-modal'>
							<FontAwesomeIcon
								icon={faXmark}
								className='close-icon'
								onClick={() => hide()}
							/>
							<ModalBody />
						</div>
					</div>
				</React.Fragment>,
				document.body,
		  )
		: null;

export default Modal;
