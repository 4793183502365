import React from 'react';
import ReactDOM from 'react-dom';
import setupAxios from './config/axiosConfig';
import i18n from './i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';

setupAxios();
moment.locale('ja');

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals();
