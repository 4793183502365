import { configureStore } from '@reduxjs/toolkit';
// import todoSlice from './todo-slice'
import authReducer from './auth/auth';
import messageReducer from './message/message';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const reducer = {
    auth: authReducer,
    message: messageReducer,
};

const store = configureStore({
    reducer: reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
