import React, { useState, useEffect } from "react";
import "./header.css";
import iconParking from "../../assets/imgs/Group.png";
import { faBell, faXmark, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import authService from "services/authService";
import Modal from "../Modal/Modal";
import useModal from "../../hooks/useModal";

declare let window: any;

const Header = () => {
	const [hide, setHide] = useState(false);
	const [listMessage, setListMessage] = useState<any>([]);
	let path = window.location.pathname;
	const { isShowing, toggle } = useModal();

	const getLog = async () => {
		const data = await authService?.getLogErr();
		if (!data?.code && data?.length > 0) {
			setListMessage(data);
		} else {
			setListMessage([]);
		}
	};

	useEffect(() => {
		if (path === "/login" || path === "/parking-cal") {
			setHide(true);
		}
	}, []);

	useEffect(() => {
		getLog();
		const interval = setInterval(() => {
			getLog();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div
			className='header flex-row'
			style={{
				display: hide ? "none" : "",
			}}
		>
			<img width={"33px"} height={"26px"} src={iconParking} />
			<div className='header-title'>駐車台数監視システム</div>
			<FontAwesomeIcon
				icon={faBell}
				className='bell-icon'
				onClick={() => toggle()}
			/>
			{listMessage?.length > 0 && (
				<div className='error-list' onClick={() => toggle()}>
					<label>{listMessage?.length}</label>
				</div>
			)}
			<Modal
				isShowing={isShowing}
				hide={toggle}
				ModalBody={() => {
					return (
						<div className='list-alerts d-flex flex-column'>
							{listMessage?.length > 0 ? (
								listMessage?.map((item: any, id: number) => (
									<div className='alerts-item'>
										<label className='alerts-title'>{item?.title} </label>
										<span className='alerts-des'>{item?.description}</span>
									</div>
								))
							) : (
								<div className='alerts-empty d-flex flex-column al-center j-center'>
									<FontAwesomeIcon icon={faList} className='list-empty' />
									空のリストです。
								</div>
							)}
						</div>
					);
				}}
			/>
		</div>
	);
};
export default Header;
