import { signalStatus } from "./const";

const loraStatus = (rssi: number, sni: number) => {
  if (rssi === 0 && sni === 0) {
    return "チェック中";
  }
  else if (rssi > -70 && sni > 0) {
    return signalStatus[2];
  } else if (rssi < -70 && rssi > -90 && sni < 0 && sni > -10) {
    return signalStatus[1];
  } else if (rssi < -70 && rssi > -90 && sni > 0) {
    return signalStatus[1];
  } else if (rssi > -70 && sni < 0 && sni > -10) {
    return signalStatus[1];
  } else {
    return signalStatus[0];
  }
};

const checkStatus = (item: any) => {
  let isLora = checkLoraSim(item);
  let status = '';
  status = isLora ? loraStatus(item?.rssi, item?.sni) : item?.internet_status ? signalStatus[3] : signalStatus[4];

  return status;
};

const checkLoraSim = (cam: any) => {
  let isLora = true;

  if (cam?.lora) {
    isLora = true;
  } else if (cam?.sim) {
    isLora = false;
  } else {
    isLora = true;
  }

  return isLora;
};


export {
  checkStatus, checkLoraSim
};