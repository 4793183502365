import React, { useState, useEffect } from "react";
import "./parkings.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useModal } from "react-hooks-use-modal";
import parkingsService from "../../services/parkingsService";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import camerasService from "../../services/cameraService";
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";

const animatedComponents = makeAnimated();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#3A4C7A",
		color: "#FFFFFF",
		fontSize: "18px",
		fontStyle: "normal",
		lineHeight: "22px",
		fontWeight: 400,
		fontFamily: "Inter",
	},
	[`&.${tableCellClasses.body}`]: {
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: "18px",
		lineHeight: "22px",
		color: "#040C24",
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const Parkings = (props) => {
	const { setLoading } = props;
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		criteriaMode: "all",
	});
	const [Modal, open, close, isOpen] = useModal("root", {});
	const [parkingsList, setParkingsList] = useState<any>(null);
	const [parkingEdit, setParkingEdit] = useState<any>(null);
	const [isChangeParkings, setChangeParkings] = useState<any>(false);
	const [maxCar, setMaxCar] = useState<number>(0);
	const [listCameras, setListCameras] = useState<any[]>([]);
	const [listFreeCameras, setListFreeCameras] = useState<any[]>([]);

	const notify = () =>
		toast.success("更新に成功", {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
			theme: "light",
		});

	const getListParkings = async (page = 1, data = {}, canLoading) => {
		try {
			canLoading && setLoading(true);
			const response = await parkingsService.getListParkings({ page, ...data });
			if (response !== null && response !== undefined) {
				setParkingsList(response.items);
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			canLoading && setLoading(false);
		}
	};

	const handleEdit = (row) => {
		setParkingEdit(row);
	};

	const onChangeMaxCar = (e) => {
		setMaxCar(e.target.value);
	};

	useEffect(() => {
		getListParkings(1, {}, true);
		const interval = setInterval(() => {
			getListParkings(1, {}, false);
		}, 3000);
		return () => clearInterval(interval);
	}, [isChangeParkings]);

	useEffect(() => {
		if (parkingEdit !== null) {
			getListCameras();
			reset({
				now_num_cars: parkingEdit?.now_num_cars,
				max_num_cars: parkingEdit?.max_num_cars,
				name: parkingEdit?.name,
				location: parkingEdit?.location,
				is_closed: parkingEdit?.is_closed,
				is_adjusted: parkingEdit?.is_adjusted,
			});
			setMaxCar(parkingEdit?.max_num_cars);
		}
	}, [parkingEdit]);

	const handleUpdateParking = async (data) => {
		let camIds: number[] = [];
		if (data?.list_cams?.length > 0) {
			data?.list_cams?.map((cam: any) => {
				camIds.push(cam?.value);
			});
		}
		let body = {
			name: data?.name,
			max_num_cars: data?.max_num_cars,
			now_num_cars: data?.now_num_cars,
			location: data?.location,
			is_closed: data?.is_closed,
			is_adjusted: data?.is_adjusted,
			camera_ids: camIds,
		};
		try {
			const response = await parkingsService.updateParking(
				parkingEdit.id,
				body,
			);
			if (response !== null && response !== undefined) {
				setChangeParkings(!isChangeParkings);
				notify();
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			close();
		}
	};
	const getListCameras = async () => {
		setLoading(true);
		try {
			const response = await camerasService.getCamera();
			if (response !== null && response !== undefined) {
				setListCameras(response.items);
			}
			let listCamFree: any = [];
			let listCamSelected: any = [];
			response?.items?.map((item: any) => {
				if (item?.parking_id && item?.parking_id === parkingEdit?.id) {
					listCamFree.push({ value: item?.id, label: item?.name });
					listCamSelected.push({ value: item?.id, label: item?.name });
				} else if (!item?.parking_id) {
					listCamFree.push({ value: item?.id, label: item?.name });
				}
			});
			setListFreeCameras(listCamFree);
			setListCameras(listCamSelected);
		} catch (err) {
			console.log("err", err);
		} finally {
			setLoading(false);
			open();
		}
	};

	useEffect(() => {
		if (!isOpen) {
			setParkingEdit(null);
			setListFreeCameras([]);
			setListCameras([]);
		}
	}, [isOpen]);

	return (
		<div className='parkings'>
			<div className='parkings-container d-flex flex-column'>
				<div className='parkings-title'>駐車場</div>
				<div className='parkings-table'>
					<Modal>
						<div className='parking-setting d-flex flex-column'>
							<form onSubmit={handleSubmit(handleUpdateParking)}>
								<div className='parking-setting-title d-flex flex-row j-center al-center truncate-text'>
									<label className='truncate-text'>
										{parkingEdit?.name}台数設定
									</label>
								</div>

								<div className='parking-setting-form d-flex flex-column'>
									<div className='input-parking-setting d-flex flex-column'>
										<label>
											駐車場<span className='err-message'>*</span>
										</label>
										<div>
											<input
												autoComplete='off'
												type='text'
												{...register("name", {
													required: "駐車場は必須項目です。",
												})}
											/>
										</div>
										{errors.name && (
											<span className='err-message'>
												駐車場は必須項目です。
											</span>
										)}
									</div>
								</div>

								<div className='parking-setting-form d-flex flex-column'>
									<div className='input-parking-setting d-flex flex-column'>
										<label>
											駐車台数<span className='err-message'>*</span>
										</label>
										<div className='text-14'>
											<input
												autoComplete='off'
												type='number'
												{...register("now_num_cars", {
													required: "駐車台数場は必須項目です。",
													min: {
														value: 0,
														message: "車の数は 0 より大きくなければなりません",
													},
													max: {
														value: maxCar,
														message: `車の数は ${maxCar} 未満である必要があります`,
													},
												})}
											/>
											台
										</div>
										{errors.now_num_cars && (
											<span className='err-message'>
												{errors?.now_num_cars?.message?.toString()}
											</span>
										)}
									</div>
								</div>
								<div className='parking-setting-form d-flex flex-column'>
									<div className='input-parking-setting d-flex flex-column'>
										<label>
											最大台数<span className='err-message'>*</span>
										</label>
										<div className='text-14'>
											<input
												autoComplete='off'
												type='number'
												{...register("max_num_cars", {
													onChange: (e) => onChangeMaxCar(e),
													required: "最大台数場は必須項目です。",
													min: {
														value: 1,
														message: "車の数は 1 より大きくなければなりません",
													},
												})}
											/>
											台
										</div>
										{errors.max_num_cars && (
											<span className='err-message'>
												{errors?.max_num_cars?.message?.toString()}
											</span>
										)}
									</div>
								</div>

								<div className='parking-setting-form d-flex flex-column'>
									<div className='input-parking-setting d-flex flex-column'>
										<label>カメラ グループ</label>
										<div className='text-14'>
											<Controller
												control={control}
												name='list_cams'
												defaultValue={listCameras}
												render={({ field: { onChange, value } }) => (
													<Select
														onChange={onChange}
														className='select-cams'
														classNamePrefix='react-select'
														closeMenuOnSelect={false}
														defaultValue={listCameras}
														value={value}
														components={animatedComponents}
														isMulti
														options={listFreeCameras}
														placeholder=''
													/>
												)}
											/>
										</div>
									</div>
								</div>

								{/* <div className='parking-setting-form d-flex flex-column'>
									<div className='input-parking-setting d-flex flex-column'>
										<label>位置</label>
										<div className='text-14'>
											<input
												autoComplete='off'
												{...register("location", {
													required: false,
												})}
											/>
										</div>
									</div>
								</div> */}

								<div className='parking-setting-form d-flex flex-column'>
									<div className='input-parking-setting d-flex flex-row'>
										<label>閉鎖中</label>
										<div className='text-14'>
											<Controller
												control={control}
												name='is_closed'
												defaultValue={false}
												render={({ field: { onChange, value } }) => (
													<Switch
														onChange={(newValue) => {
															setValue("is_closed", newValue);
															newValue && setValue("is_adjusted", !newValue);
														}}
														checked={value}
														height={20}
														width={40}
														onColor='#3A4C7A'
														className='switch-close'
													/>
												)}
											/>
										</div>
									</div>
								</div>

								<div className='parking-setting-form d-flex flex-column'>
									<div className='input-parking-setting d-flex flex-row'>
										<label>調整中</label>
										<div className='text-14'>
											<Controller
												control={control}
												name='is_adjusted'
												defaultValue={false}
												render={({ field: { onChange, value } }) => (
													<Switch
														onChange={(newValue) => {
															setValue("is_adjusted", newValue);
															newValue && setValue("is_closed", !newValue);
														}}
														checked={value}
														height={20}
														width={40}
														onColor='#3A4C7A'
														className='switch-close'
													/>
												)}
											/>
										</div>
									</div>
								</div>

								<div className='parking-setting-buttons d-flex flex-row w-full'>
									<button
										className='parking-setting-button1 d-flex flex-row j-center al-center'
										onClick={close}
									>
										閉じる
									</button>
									<button
										className='parking-setting-button1 d-flex flex-row j-center al-center'
										type='submit'
									>
										更新
									</button>
								</div>
							</form>
						</div>
					</Modal>
					<TableContainer component={Paper}>
						<Table aria-label='customized table'>
							<TableHead>
								<TableRow>
									<StyledTableCell align='left'>駐車場</StyledTableCell>
									<StyledTableCell align='left'>台数</StyledTableCell>
									{/* <StyledTableCell align='left'>出入口</StyledTableCell> */}
									<StyledTableCell align='center'>操作</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{parkingsList !== null &&
									parkingsList.length > 0 &&
									parkingsList.map(
										(item, id) =>
											id < 17 && (
												<StyledTableRow key={id}>
													<StyledTableCell
														align='left'
														component='th'
														scope='row'
													>
														<div className='parkings-row'>{item.name}</div>
													</StyledTableCell>

													<StyledTableCell
														align='left'
														component='th'
														scope='row'
													>
														<div className='parkings-row'>
															{item.now_num_cars}台｜{item.max_num_cars}台
														</div>
													</StyledTableCell>

													{/* <StyledTableCell
														align='left'
														component='th'
														scope='row'
													>
														<div className='parkings-row'>入口A・入口B</div>
													</StyledTableCell> */}

													<StyledTableCell align='center'>
														<button
															className='button-tb'
															onClick={() => handleEdit(item)}
														>
															台数設定
														</button>
													</StyledTableCell>
												</StyledTableRow>
											),
									)}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
			<ToastContainer
				position='top-right'
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='colored'
			/>
		</div>
	);
};
export default Parkings;
