import React, { useState, useEffect } from "react";
import "./cameras.css";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import camerasService from "../../services/cameraService";
import { useNavigate } from "react-router-dom";
import { status } from "../../ultils/const";
import SignalStatus from "./SignalStatus";
import { checkLoraSim } from "ultils/helper";
import { useModal } from "react-hooks-use-modal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#3A4C7A",
		color: "#FFFFFF",
		fontSize: "18px",
		fontStyle: "normal",
		lineHeight: "22px",
		fontWeight: 400,
		fontFamily: "Inter",
		// whiteSpace: "nowrap",
	},
	[`&.${tableCellClasses.body}`]: {
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: "18px",
		lineHeight: "22px",
		color: "#040C24",
		// whiteSpace: "nowrap",
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const Items = (props) => {
	const {
		listCamera,
		camerasRq,
		getListCameras,
		setOpenOffCam,
		setOpenResetCam,
		setUpdate,
		isUpdated,
	} = props;
	const navigate = useNavigate();

	const [itemModal, setItemModal] = useState<any>(null);
	const [Modal, open, close, isOpen] = useModal("root");

	return (
		<TableBody>
			{listCamera !== null &&
				listCamera.length > 0 &&
				listCamera.map(
					(item: any, id: number) =>
						id < 17 && (
							<StyledTableRow key={id}>
								<StyledTableCell align='left' component='th' scope='row'>
									<div className='parkings-row'>{item?.name}</div>
								</StyledTableCell>

								<StyledTableCell align='left' component='th' scope='row'>
									<div className='parkings-row'>{item?.parking_name}</div>
								</StyledTableCell>

								<StyledTableCell align='left' component='th' scope='row'>
									<div className='parkings-row'>
										{item?.status === 0
											? status[0]
											: item?.status === 1
											? status[1]
											: status[2]}
									</div>
								</StyledTableCell>

								<StyledTableCell align='left' component='th' scope='row'>
									<div className='parkings-row'>
										{item?.status_send_image === 0
											? "未アクティブ"
											: item?.status_send_image === 1
											? "処理中"
											: item?.status_send_image === 2
											? "失敗"
											: item?.status_send_image === 3
											? "成功"
											: "未アクティブ"}
									</div>
								</StyledTableCell>

								<StyledTableCell align='left' component='th' scope='row'>
									<SignalStatus item={item} />
								</StyledTableCell>

								<StyledTableCell align='center'>
									<div className='gb-cam'>
										<button
											className={camerasRq ? "button-tb2" : "button-tb3"}
											disabled={camerasRq}
											onClick={() => {
												camerasService.requestCameraImage(item.id);
												setTimeout(() => {
													getListCameras();
												}, 1000);
											}}
											style={{ width: "170px" }}
										>
											画像をリクエスト
										</button>
										<button
											className='button-tb'
											onClick={() =>
												navigate(`/cameras/${item?.id}`, {
													state: item,
												})
											}
										>
											識別領域設定
										</button>

										<button
											className='button-tb button-sim'
											onClick={async () => {
												setItemModal(item);
												open();
											}}
										>
											{checkLoraSim(item) ? "SIM" : "LORA"}
										</button>

										{listCamera && (
											<button
												className={`button-tb w-120 ${
													item?.status === 0 || item?.turn_on_off
														? "bg-disable"
														: "bg-delete"
												} `}
												disabled={item?.status === 0 || item?.turn_on_off}
												onClick={() => setOpenOffCam(item)}
											>
												電源OFF
											</button>
										)}

										<button
											className='button-tb'
											onClick={async () => setOpenResetCam(item)}
											style={{ width: "100px" }}
										>
											リセット
										</button>
									</div>
								</StyledTableCell>
							</StyledTableRow>
						),
				)}

			<Modal>
				<div className='off-cam-modal d-flex flex-column al-center'>
					<label className='title-off-cam'>
						{checkLoraSim(itemModal) ? "SIM" : "LORA"} を使用しますか?
					</label>
					<div className='off-cam-buttons-gr d-flex flex-row w-full'>
						<button
							className='off-cam-buttons d-flex flex-row j-center al-center'
							onClick={async () => {
								let isLora = checkLoraSim(itemModal);
								const data = await camerasService?.updateCamSimLora(
									itemModal?.id,
									!isLora,
									isLora,
								);
								if (data) {
									setUpdate(!isUpdated);
									camerasService?.resetCamStatus(itemModal?.id);
									setItemModal(null);
									close();
								}
							}}
						>
							アップデート
						</button>
						<button
							className='off-cam-buttons d-flex flex-row j-center al-center bg-delete'
							onClick={() => close()}
						>
							キャンセル
						</button>
					</div>
				</div>
			</Modal>
		</TableBody>
	);
};
export default Items;
